*{
    font-family: 'Roboto', sans-serif;
}

.profile-wrapper {
    padding: 2px;
    border-radius: 12px;
    background: linear-gradient(to right, #FF1111, #630000);
    margin:15px 5px ;
}

.profile-content {
    border-radius: 10px;
    background: linear-gradient(to right, #FCFCFC, #E3E3E3);
    padding: 15px;
}

.info {
    display: flex;
    justify-content: space-between;
    padding: 7px 0;
}

.info span {
    font-size: 16px;
    color: #393939;
    font-weight: 420;
}
