.status-bar {
    height: 10vh;
}

.header-logo {
    display: flex;
    justify-content: center;
    position: relative;
}

.main-logo {
    display: flex;
    max-width: 400px;
    width: 60%;
    height: auto;
}
.main-logo img{
    width: 100%;
    height: auto;
    display: flex;
}
.img-icon-back{
    position: absolute;
    left: 18px;
    /* top: 0vh; */
    z-index: 98;
}
.header p {
    text-align: center;
    font-size: 24px;
    color: #393939;
    margin: 0;
    font-weight: 700;
    font-family: "Inter", sans-serif;
    line-height: 29px;
    margin-top: 12px;
}
@media only screen and (max-width: 780px) {
    .status-bar {
        height: 3vh;
    }
}