@import url("https: //fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

body{
    margin: 0;
}
.container{
    background-image: url("../../imgs/mb66/bg-cskh.png");
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    height: 100%;
    background-repeat: repeat;
    background-size: 100% 100%;
}

.app{
    max-width: 430px;
    width: 100%;
    height: 100%;
    min-height: 100vh;
    margin: 0 auto;
    background-image: url("../../imgs/mb66/bg-content-cskh.png");
    /* background-size: cover; */
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat;
}

.content {
    padding-left:18px;
    padding-right:18px;
    font-family: "Roboto", sans-serif;
}
.error {
    color: #f70f0ff2;
    font-size: 14px;
    font-style: italic;
}
p.error {
    margin: 0px 10px;
}

@media only screen and (max-width: 780px) {

    .app{
        max-width: 430px;
        width: 100%;
        height: 100%;
        min-height: 100vh;
        margin: 0 auto;
        background-image: url("../../imgs/mb66/bg-content-edit.png");
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
    }
}